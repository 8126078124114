import isObject from './isObject';
/**
 * @see https://stackoverflow.com/a/5878101
 */ export default function isPlainObject(argument) {
    if (isObject(argument)) {
        if ('function' == typeof Object.getPrototypeOf) {
            const proto = Object.getPrototypeOf(argument);
            if ('object' == typeof proto) {
                // Case: Object.create(null);
                if (null === proto) return true;
                if (!(proto.constructor === Object || proto === Object.prototype)) return false;
            }
        }
        return '[object Object]' === Object.prototype.toString.call(argument);
    }
    return false;
}
