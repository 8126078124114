import { getNavigationConfig } from '@leon-hub/api-sdk/src/sdk/sdk';
import { isNavigationConfig } from '@leon-hub/navigation-config/src/types/NavigationConfig';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
export async function fetchDefaultNavigationConfig() {
    let silent = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
    const result = await getNavigationConfig(useGraphqlClient(), (node)=>node.queries.system.getNavigation, {}, {
        silent
    });
    const parsedNavigationConfig = JSON.parse(result.navigation);
    isNavigationConfig(parsedNavigationConfig);
    return parsedNavigationConfig;
}
