export var IconSize;
var IconSize1;
(IconSize1 = IconSize || (IconSize = {}))["MINI"] = "mini", IconSize1["TINY"] = "tiny", IconSize1["SMALL"] = "small", IconSize1["DEFAULT"] = "default", IconSize1["MEDIUM"] = "medium", IconSize1["BIG"] = "big", IconSize1["LARGE"] = "large", IconSize1["HUGE"] = "huge", IconSize1["GIANT"] = "giant", IconSize1["ICON_130"] = "icon_130", IconSize1["ICON_160"] = "icon_160", IconSize1["SIZE_8"] = "size-8", IconSize1["SIZE_10"] = "size-10", IconSize1["SIZE_12"] = "size-12", IconSize1["SIZE_14"] = "size-14", IconSize1["SIZE_16"] = "size-16", IconSize1["SIZE_18"] = "size-18", IconSize1["SIZE_20"] = "size-20", IconSize1["SIZE_24"] = "size-24", IconSize1["SIZE_28"] = "size-28", IconSize1["SIZE_32"] = "size-32", IconSize1["SIZE_36"] = "size-36", IconSize1["SIZE_40"] = "size-40", IconSize1["SIZE_44"] = "size-44", IconSize1["SIZE_48"] = "size-48", IconSize1["SIZE_50"] = "size-50", IconSize1["SIZE_52"] = "size-52", IconSize1["SIZE_56"] = "size-56", IconSize1["SIZE_60"] = "size-60", IconSize1["SIZE_68"] = "size-68", IconSize1["SIZE_80"] = "size-80", IconSize1["SIZE_90"] = "size-90", IconSize1["SIZE_96"] = "size-96", IconSize1["SIZE_100"] = "size-100", IconSize1["SIZE_130"] = "size-130", IconSize1["SIZE_160"] = "size-160", IconSize1["FULL_SIZE"] = "full-size"; /** @deprecated */  // 8x8
 /** @deprecated */  // 12x12
 /** @deprecated */  // 16x16
 /** @deprecated */  // 20x20
 /** @deprecated */  // 24x24
 /** @deprecated */  // 32x32
 /** @deprecated */  // 48x48
 /** @deprecated */  // 60x60
 /** @deprecated */  // 80x80
 /** @deprecated */  // 130x130
 /** @deprecated */  // 160x160
 // 8x8
 // 10x10
 // 12x12
 // 12x12
 // 16x16
 // 18x18
 // 20x20
 // 24x24
 // 24x24
 // 32x32
 // 36x36
 // 40x40
 // 44x44
 // 48x48
 // 50x50
 // 52x52
 // 56x56
 // 60x60
 // 68x68
 // 80x80
 // 90x90
 // 96х96
 // 100x100
 // 130x130
 // 160x160
 // 100%x100%
