import getObjectDescription from './getObjectDescription';
export default function getRequestDescription(request) {
    return getObjectDescription({
        url: request.url,
        cache: request.cache,
        credentials: request.credentials,
        destination: request.destination,
        headers: [
            ...request.headers.entries()
        ].reduce((accumulator, param)=>{
            let [key, value] = param;
            return {
                ...accumulator,
                [key]: value
            };
        }, {}),
        integrity: request.integrity,
        keepalive: request.keepalive,
        method: request.method,
        redirect: request.redirect,
        referrer: request.referrer,
        referrerPolicy: request.referrerPolicy,
        body: request.body
    }, {
        prefix: 'request '
    });
}
