import { _ as _define_property } from "@swc/helpers/_/_define_property";
import GqlApiErrorCode from './GqlApiErrorCode';
let GqlApiResponseErrorCode = class GqlApiResponseErrorCode extends GqlApiErrorCode {
    constructor(code){
        super(code);
    }
};
_define_property(GqlApiResponseErrorCode, "GQL_API_SERVICE_INVALID_SYNTAX", new GqlApiResponseErrorCode('InvalidSyntax'));
_define_property(GqlApiResponseErrorCode, "GQL_API_SERVICE_VALIDATION_ERROR", new GqlApiResponseErrorCode('ValidationError'));
_define_property(GqlApiResponseErrorCode, "GQL_API_SERVICE_DATA_FETCHING_EXCEPTION", new GqlApiResponseErrorCode('DataFetchingException'));
_define_property(GqlApiResponseErrorCode, "GQL_API_SERVICE_OPERATION_NOT_SUPPORTED", new GqlApiResponseErrorCode('OperationNotSupported'));
_define_property(GqlApiResponseErrorCode, "GQL_API_SERVICE_EXECUTION_ABORTED", new GqlApiResponseErrorCode('ExecutionAborted'));
export { GqlApiResponseErrorCode as default };
