const primitivesTypes = [
    'string',
    'number',
    'bigint',
    'boolean',
    'symbol',
    'undefined'
];
function isPrimitive(value, target) {
    if (void 0 === target) return null === target || primitivesTypes.includes(typeof target);
    return value === target;
}
export default isPrimitive;
export function createPrimitiveGuard(primitive) {
    return (value)=>isPrimitive(value, primitive);
}
