import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { _ as _ts_decorate } from "@swc/helpers/_/_ts_decorate";
import { _ as _ts_metadata } from "@swc/helpers/_/_ts_metadata";
import { v4 as uuidV4 } from 'uuid';
import { Deferred, Memoized } from '@leon-hub/utils';
import { isNumber } from '@leon-hub/guards';
import { RequestOptionsPriority } from '@leon-hub/api-sdk';
import isMutation from './isMutation';
import hashContent from './utils/hashContent';
let GqlBatchedSubRequest = class GqlBatchedSubRequest {
    setPriority(priority) {
        this.priority = priority;
    }
    getPriority() {
        return this.priority;
    }
    incrementFailedAttempts() {
        this.failedAttempts += 1;
    }
    updateFailedAttempts() {
        let value = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : 0;
        this.failedAttempts = value;
    }
    getFailedAttempts() {
        return this.failedAttempts;
    }
    getContent() {
        return GqlBatchedSubRequest.getCachedContent(this.content);
    }
    getContentHash() {
        return hashContent(this.getContent());
    }
    getOperationName() {
        const splittedRequestName = this.getName().split(':');
        return 'unknown' !== splittedRequestName[1] ? splittedRequestName[1] : void 0;
    }
    static getCachedContent(content) {
        return content.trim().replace(/\s{2,}/gm, ' ').replace(/([\n\r])[\n\r]*\s+/gm, '$1');
    }
    getCacheTimestamp() {
        if (this.mutation) return 0;
        return this.ts;
    }
    setCacheTimestamp(ts) {
        isNumber(ts);
        this.ts = ts;
    }
    isPossiblyCached() {
        if (this.mutation) return false;
        return this.getCacheTimestamp() > 0;
    }
    resetCacheTimestamp() {
        this.ts = 0;
    }
    getHeaders() {
        return Object.keys(this.headers).reduce((accumulator, headerKey)=>{
            const headerValue = this.headers[headerKey];
            if (!headerValue) return accumulator;
            const headerKeyLowCase = headerKey.toLowerCase();
            accumulator[headerKeyLowCase] = headerValue;
            return accumulator;
        }, {});
    }
    getCacheKey() {
        const { ts, ...options } = this.options || {};
        const headers = this.getHeaders();
        const content = this.getContent();
        return JSON.stringify({
            customId: this.customId,
            content,
            options,
            headers
        });
    }
    getName() {
        return GqlBatchedSubRequest.getCachedName(this.getContent());
    }
    static getCachedName(content) {
        const match = /(query|mutation)\s+(\w+)\b/m.exec(content);
        if (!match) // eslint-disable-next-line no-console
        // eslint-disable-next-line no-console
        // eslint-disable-next-line no-console
        return 'unknown:unknown';
        return `${match[1]}:${match[2]}`;
    }
    toJSON() {
        return {
            id: this.id,
            customId: this.customId,
            priority: this.priority,
            ts: this.ts,
            created: this.created,
            failedAttempts: this.failedAttempts,
            options: this.options,
            name: this.getName(),
            headers: this.getHeaders(),
            timeout: this.timeout
        };
    }
    getTimeout() {
        return this.timeout;
    }
    getRetry() {
        return this.retry;
    }
    getGroup() {
        return this.group;
    }
    constructor({ content, resolver, options, silent, group, headers = {}, id, priority, timeout = 0, retry }){
        _define_property(this, "ts", 0);
        _define_property(this, "failedAttempts", 0);
        _define_property(this, "content", void 0);
        _define_property(this, "customId", void 0);
        _define_property(this, "mutation", void 0);
        _define_property(this, "options", {});
        _define_property(this, "headers", void 0);
        _define_property(this, "silent", void 0);
        _define_property(this, "created", void 0);
        _define_property(this, "deferred", void 0);
        _define_property(this, "promise", void 0);
        _define_property(this, "id", void 0);
        _define_property(this, "group", void 0);
        _define_property(this, "resolver", void 0);
        _define_property(this, "priority", RequestOptionsPriority.NORMAL);
        _define_property(this, "timeout", void 0);
        _define_property(this, "retry", void 0);
        this.priority = priority ?? this.priority;
        this.created = Date.now();
        this.content = content.trim();
        this.options = options || {};
        this.resolver = resolver;
        this.headers = Object.freeze(headers);
        this.silent = silent ?? false;
        this.mutation = isMutation(content);
        this.deferred = new Deferred();
        this.promise = this.deferred.promise;
        this.customId = id;
        this.id = id || uuidV4();
        this.timeout = timeout;
        this.retry = retry;
        this.group = group;
    }
};
export { GqlBatchedSubRequest as default };
_ts_decorate([
    Memoized({
        shared: true
    }),
    _ts_metadata("design:type", Function),
    _ts_metadata("design:paramtypes", [
        String
    ]),
    _ts_metadata("design:returntype", String)
], GqlBatchedSubRequest, "getCachedContent", null);
_ts_decorate([
    Memoized(),
    _ts_metadata("design:type", Function),
    _ts_metadata("design:paramtypes", []),
    _ts_metadata("design:returntype", "undefined" == typeof Record ? Object : Record)
], GqlBatchedSubRequest.prototype, "getHeaders", null);
_ts_decorate([
    Memoized(),
    _ts_metadata("design:type", Function),
    _ts_metadata("design:paramtypes", []),
    _ts_metadata("design:returntype", String)
], GqlBatchedSubRequest.prototype, "getCacheKey", null);
_ts_decorate([
    Memoized(),
    _ts_metadata("design:type", Function),
    _ts_metadata("design:paramtypes", []),
    _ts_metadata("design:returntype", String)
], GqlBatchedSubRequest.prototype, "getName", null);
_ts_decorate([
    Memoized({
        shared: true
    }),
    _ts_metadata("design:type", Function),
    _ts_metadata("design:paramtypes", [
        String
    ]),
    _ts_metadata("design:returntype", String)
], GqlBatchedSubRequest, "getCachedName", null);
