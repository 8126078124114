import { _ as _define_property } from "@swc/helpers/_/_define_property";
let AbstractErrorCode = class AbstractErrorCode {
    is(Constructor) {
        return this instanceof Constructor;
    }
    equals(code) {
        return this.code === code.toString();
    }
    toString() {
        return this.code;
    }
    constructor(code){
        _define_property(this, "code", void 0);
        this.code = code;
    }
};
export { AbstractErrorCode as default };
