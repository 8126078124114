import { QueryParameterName } from '@leon-hub/query-manager';
import { getQueryParams } from '@leon-hub/service-locator-env';
import { EnvValueEnabled } from '@leon-hub/environment-common';
export const createPrerenderEnv = ()=>{
    const queryParams = getQueryParams();
    if (queryParams[QueryParameterName.PRERENDER] === EnvValueEnabled) return {
        VUE_APP_PRERENDER: EnvValueEnabled
    };
    return {};
};
