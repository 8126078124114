/* eslint-disable no-console */ import { isObject } from '@leon-hub/guards';
import { Json } from '@leon-hub/utils';
import { isNavigationItem } from './NavigationItem';
import { isNavigationGroup } from './NavigationGroup';
export function isNavigationConfig(value) {
    return isObject(value) && Object.keys(value).every((key)=>{
        if (!isNavigationGroup(key)) console.error(`Navigation group ${key} is not declared in enum NavigationGroup`);
        const navigationItems = value[key];
        if (Array.isArray(navigationItems)) {
            for (const item of navigationItems)if (!isNavigationItem(item)) console.error(`Invalid navigation item: group: ${key}; item=${Json.stringify(item)}`);
        } else console.error(`Navigation items are not an array=${Json.stringify(navigationItems)}`);
        return true;
    });
}
