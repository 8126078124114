import { normalizeError } from '@leon-hub/errors';
import { ApiError } from '../../errors/ApiError';
import GqlApiError from './GqlApiError';
let defaultErrorMessage;
const convertToGqlApiError = Object.assign(function(error) {
    let message = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : defaultErrorMessage;
    if (error instanceof ApiError) return error;
    return new GqlApiError({
        message,
        originalError: normalizeError(error)
    });
}, {
    setDefaultErrorMessage: (message)=>{
        defaultErrorMessage = message;
    }
});
export default convertToGqlApiError;
