const gqlCache = new Map();
export function cleanGqlFragmentDuplication(gqlDocument) {
    if (!gqlDocument.includes('fragment')) return gqlDocument;
    const cache = gqlCache.get(gqlDocument);
    if (void 0 !== cache) return cache;
    const trimmedOutput = gqlDocument.trim();
    const parts = trimmedOutput.split(/\bfragment /).map((str)=>str.trim()).filter(Boolean);
    const hasDocument = trimmedOutput.match(/^(query|mutation) /);
    const [query, ...fragments] = hasDocument ? parts : [
        '',
        ...parts
    ];
    const result = query + fragments.map((fragment, index, list)=>{
        if (list.indexOf(fragment) === index) return `fragment ${fragment}`;
        return '';
    }).join('\n');
    gqlCache.set(gqlDocument, result);
    return result;
}
