import { Validator } from 'jsonschema';
const isDataValidJsonSchema = function(input, schema) {
    let noEmitErrorToConsole = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : false;
    const validator = new Validator();
    const result = validator.validate(input, schema);
    if (result.valid) return true;
    if (noEmitErrorToConsole) return false;
    // eslint-disable-next-line no-console
    console.error('invalid input', result.errors);
    return false;
};
export default isDataValidJsonSchema;
