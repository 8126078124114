import isObject from './isObject';
function log(param) {
    let { target, value, key, guards, guard } = param;
    // eslint-disable-next-line no-console
    console.groupCollapsed(`All guards failed for key=${String(key)}`);
    // eslint-disable-next-line no-console
    console.log('key', key);
    // eslint-disable-next-line no-console
    console.log('value', value);
    // eslint-disable-next-line no-console
    console.log('target', target);
    if (guards) // eslint-disable-next-line no-console
    console.log('guards', guards);
    if (guard) // eslint-disable-next-line no-console
    console.log('guard', guard);
    // eslint-disable-next-line no-console
    console.groupEnd();
}
export default function isValidInterface(target, fieldGuards) {
    let { silent = false } = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {};
    return isObject(target) && [
        ...Object.keys(fieldGuards),
        ...Object.getOwnPropertySymbols(fieldGuards)
    ].every((key)=>{
        const guards = fieldGuards[key];
        const value = target[key];
        let result;
        if (Array.isArray(guards)) {
            result = guards.some((guard)=>guard(value));
            if (!result && !silent) log({
                key,
                value,
                target,
                guards
            });
        } else {
            const guard = guards;
            result = guard(value);
            if (!result && !silent) log({
                key,
                value,
                target,
                guard
            });
        }
        return result;
    });
}
export function bindIsValidInterface(fieldGuards) {
    return (value)=>isValidInterface(value, fieldGuards);
}
