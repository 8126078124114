import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { logger } from '@leon-hub/logging';
import { isNullOrUndefined } from '@leon-hub/guards';
import { getLargestKey } from '../utils/getLargestKey';
import { isQuotaExceededError } from '../utils/isQuotaExceededError';
export class LocalStorageManager {
    key(index) {
        return this.storage.key(index);
    }
    clear(saveKeys) {
        const savedKeys = {};
        if (saveKeys?.length) for (const key of saveKeys){
            const value = localStorage.getItem(key);
            if (!isNullOrUndefined(value)) savedKeys[key] = value;
        }
        this.storage.clear();
        for (const key of Object.keys(savedKeys))this.storage.setItem(key, savedKeys[key]);
    }
    getItem(key) {
        return this.storage.getItem(key);
    }
    get length() {
        return this.storage.length;
    }
    constructor(){
        _define_property(this, "storage", void 0);
        _define_property(this, "triedToOverwrite", false);
        _define_property(this, "setItem", (key, value)=>{
            try {
                this.storage.setItem(key, value);
            } catch (error) {
                if (isQuotaExceededError(error) && !this.triedToOverwrite) {
                    const largestKey = getLargestKey(this.storage);
                    if (void 0 !== largestKey) {
                        const largestValue = this.storage.getItem(largestKey)?.slice(0, 2000) ?? '<cannot get largest key value>';
                        logger.error(`localStorageManager: largestKey=${largestKey}, value=${largestValue}`);
                        this.removeItem(largestKey);
                    }
                    this.triedToOverwrite = true;
                    this.setItem(key, value);
                } else logger.error('localStorageManager: unable to save the value', {
                    [key]: value,
                    error
                });
            }
        });
        _define_property(this, "removeItem", (key)=>{
            this.storage.removeItem(key);
        });
        this.storage = window.localStorage;
    }
}
