import { fetchApiSettings, fetchRoutingConfigs, fetchSiteConfigSettings, makeRootSettingsRequest, fetchDefaultNavigationConfig } from 'web/src/modules/core/store/utils';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import useCoreApi from 'web/src/modules/core/services/api/useCoreApi';
import { primaryKeys } from 'web/src/modules/i18n/store/primaryKeys';
import { fetchTranslations } from 'web/src/modules/i18n/store/utils';
export default async function createPrefetchProducer() {
    const prefetchData = {
        root: makeRootSettingsRequest(),
        api: fetchApiSettings(),
        siteConfig: fetchSiteConfigSettings(),
        routing: fetchRoutingConfigs(),
        navigation: fetchDefaultNavigationConfig(),
        i18n: fetchTranslations(primaryKeys),
        serverTime: useCoreApi().syncServerTime()
    };
    // Make sure to flush all pending requests.
    await useGraphqlClient().flush({
        inParallel: true
    });
    return prefetchData;
}
