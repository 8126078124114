import { serializeHeaders } from '@leon-hub/utils';
import getObjectDescription from './getObjectDescription';
export default function getResponseDescription(response) {
    return getObjectDescription({
        url: response.url,
        status: response.status,
        statusText: response.statusText,
        headers: serializeHeaders([
            ...response.headers.entries()
        ])
    }, {
        prefix: 'response '
    });
}
