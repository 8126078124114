import isObject from './isObject';
import isArray from './isArray';
import isPlainObject from './isPlainObject';
import isNullOrUndefined from './isNullOrUndefined';
const isMeasurable = (value, measurePropertyKey)=>{
    if (!isObject(value)) return false;
    return measurePropertyKey in value;
};
export default function isEmpty(value) {
    if (isNullOrUndefined(value)) return true;
    if ('string' == typeof value && 0 === value.length) return true;
    if ('function' == typeof value) return false;
    if (isPlainObject(value) && 0 === Object.keys(value).length) return true;
    if (isArray(value) && 0 === value.length) return true;
    // eslint-disable-next-line unicorn/new-for-builtins
    const wrapped = Object(value);
    return isMeasurable(wrapped, 'size') && 0 === wrapped.size || isMeasurable(wrapped, 'length') && 0 === wrapped.length;
}
