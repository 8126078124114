function groupItemValuesByItemKeys(accumulator, item) {
    return Object.keys(item).reduce((valuesByKey, key)=>{
        const group = valuesByKey[key] || [];
        const value = item[key];
        return {
            ...valuesByKey,
            [key]: group.includes(value) ? group : group.concat(value)
        };
    }, accumulator);
}
function groupItems(list) {
    return list.reduce((accumulator, item)=>groupItemValuesByItemKeys(accumulator, item), {});
}
export default function getConflictingKeys(list) {
    if (list.length < 2) return [];
    const valuesByKey = groupItems(list);
    return Object.keys(valuesByKey).filter((key)=>valuesByKey[key].length > 1);
}
