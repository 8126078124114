import { ApiErrorCode } from '../../errors/ApiErrorCode';
import GqlApiBatchError from './GqlApiBatchError';
let GqlApiBatchHashingDisabledError = class GqlApiBatchHashingDisabledError extends GqlApiBatchError {
    constructor(options){
        super({
            ...options,
            code: ApiErrorCode.API_REQUEST_HASHING_IS_DISABLED
        });
    }
};
export { GqlApiBatchHashingDisabledError as default };
