import { AbstractError } from '@leon-hub/errors';
import { AppErrorCode } from './AppErrorCode';
export class AppError extends AbstractError {
    constructor(options){
        super({
            ...options,
            code: options?.code ?? AppErrorCode.APP_UNEXPECTED_ERROR
        });
    }
}
