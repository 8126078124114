import { isObject } from '@leon-hub/guards';
const MAX_MESSAGE_LENGTH = 200;
export default function getObjectDescription(item) {
    let { prefix } = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    isObject(item);
    return Object.entries(item).filter((param)=>{
        let [, value] = param;
        return void 0 !== value;
    }).map((param)=>{
        let [key, value] = param;
        return `${prefix || ''}${key}: ${isObject(value) ? // eslint-disable-next-line unicorn/prefer-spread
        JSON.stringify(value, null, 2).slice().slice(0, MAX_MESSAGE_LENGTH) : value}`;
    }).join('\n');
}
