import { getBootstrapTranslations } from '@leon-hub/bootstrap-translations';
import { ApiErrorCode } from '../../errors/ApiErrorCode';
import GqlApiBatchedSubRequestError from './GqlApiBatchedSubRequestError';
export class GqlApiAccessDeniedError extends GqlApiBatchedSubRequestError {
    constructor(options){
        super({
            ...options,
            code: ApiErrorCode.API_ACCESS_DENIED,
            message: getBootstrapTranslations().WEB2_SESSION_EXPIRED
        });
    }
}
