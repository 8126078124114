import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { ApiErrorCode } from '../../errors/ApiErrorCode';
import GqlApiBatchError from './GqlApiBatchError';
let GqlApiBatchUnknownHashError = class GqlApiBatchUnknownHashError extends GqlApiBatchError {
    constructor(options){
        super({
            ...options,
            code: ApiErrorCode.API_UNKNOWN_HASH
        });
        _define_property(this, "requestId", void 0);
        this.requestId = options.requestId;
    }
};
export { GqlApiBatchUnknownHashError as default };
