import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { AbstractErrorCode } from '@leon-hub/errors';
import { UnknownRequestHashExceptionCode, RequestHashingDisabledExceptionCode, AccessDeniedRemoteApiExceptionCode, PreviousRequestHasNotExpiredExceptionCode } from '@leon-hub/api-sdk';
export class ApiErrorCode extends AbstractErrorCode {
}
_define_property(ApiErrorCode, "API_UNEXPECTED_ERROR", new ApiErrorCode('UNEXPECTED_ERROR'));
_define_property(ApiErrorCode, "API_TECHNICAL_ERROR", new ApiErrorCode('TECHNICAL_ERROR'));
_define_property(ApiErrorCode, "API_SERVICE_UNAVAILABLE_ERROR_503", new ApiErrorCode('SERVICE_UNAVAILABLE_ERROR_503'));
_define_property(ApiErrorCode, "API_CONNECTION_ERROR", new ApiErrorCode('CONNECTION_ERROR'));
_define_property(ApiErrorCode, "API_REQUEST_ABORTED_ERROR", new ApiErrorCode('REQUEST_ABORTED'));
_define_property(ApiErrorCode, "API_IP_BLOCKED_ERROR", new ApiErrorCode('IP_BLOCKED'));
_define_property(ApiErrorCode, "API_EVENT_MISSING_ERROR", new ApiErrorCode('MISSING'));
_define_property(ApiErrorCode, "API_URL_NOT_FOUND", new ApiErrorCode('API_URL_NOT_FOUND'));
_define_property(ApiErrorCode, "API_UNKNOWN_HASH", new ApiErrorCode(UnknownRequestHashExceptionCode.UNKNOWN_REQUEST_HASH));
_define_property(ApiErrorCode, "API_REQUEST_HASHING_IS_DISABLED", new ApiErrorCode(RequestHashingDisabledExceptionCode.REQUEST_HASHING_IS_DISABLED));
_define_property(ApiErrorCode, "API_ACCESS_DENIED", new ApiErrorCode(AccessDeniedRemoteApiExceptionCode.ACCESS_DENIED));
_define_property(ApiErrorCode, "API_PREVIOUS_REQUEST_HAS_NOT_EXPIRED", new ApiErrorCode(PreviousRequestHasNotExpiredExceptionCode.PREVIOUS_REQUEST_HAS_NOT_EXPIRED));
