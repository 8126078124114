import stringifyQuery from './stringifyQuery';
function cleanQuery(query) {
    return query.replace(/^[&?]+|[&=]+$/g, '');
}
export default function mergeQueries(query1, query2) {
    let fullQuery = '';
    if ('string' == typeof query1) {
        if (query1.length > 0) fullQuery += cleanQuery(query1);
    } else if (Object.keys(query1).length > 0) fullQuery += stringifyQuery(query1);
    if ('string' == typeof query2) {
        if (query2.length > 0) fullQuery += fullQuery ? `&${cleanQuery(query2)}` : cleanQuery(query2);
    } else if (Object.keys(query2).length > 0) fullQuery += fullQuery ? `&${stringifyQuery(query2)}` : stringifyQuery(query2);
    return fullQuery.length > 0 ? `?${fullQuery}` : fullQuery;
}
