/* eslint-disable max-len */ const ruTranslations = Object.freeze({
    WEB2_ERROR: 'Ошибка',
    WEB2_SESSION_EXPIRED: 'Сессия истекла',
    CONNECTION_ERROR: 'Ошибка соединения. Пожалуйста, проверьте соединение с интернетом и повторите попытку.',
    WEB2_TECHNICAL_ERROR: 'Произошла техническая ошибка. Обновите страницу или повторите попытку позже.',
    JSP_PCL_FBOT_CLOSE: 'Закрыть',
    JS_CAPTION_ATTENTION: 'Внимание',
    WEB2_DO_LOGIN: 'Войти',
    JSPNAV_USTAT_REGISTER: 'Регистрация',
    WEB2_ERROR_503_TITLE_V2: 'Сайт БК Леон временно недоступен',
    WEB2_ERROR_503_TEXT1_V2: 'Проводятся технические работы по обновлению сайта.',
    WEB2_ERROR_503_TEXT2_V2: 'Совсем скоро мы снова будем с вами.',
    WEB2_ERROR_503_TEXT3_V2: 'Приносим извинения за неудобства.',
    WEB2_ERROR_503_BTN_UPDATE_V2: 'Обновить',
    WEB2_HELP_EMAIL: "info-ru@leonbets.com",
    WEB2_ERROR_BTN_GO_HOME: 'На главную',
    WEB2_ERROR_BTN_SUPPORT: 'Служба поддержки',
    WEB2_ERROR_TEXT_SUPPORT: 'Возникли трудности?<br> Мы всегда рады помочь',
    WEB2_ERROR_404_TITLE: 'Страница не найдена',
    WEB2_ERROR_404_MSG: 'Возможно, ее не существует или она была удалена.',
    WEB2_ERROR_403_TITLE: 'Доступ запрещен',
    WEB2_ERROR_403_MSG: 'Доступ к этой странице ограничен. Пожалуйста, вернитесь на Главную.',
    WEB2_CONNECTION_ERROR_CAPTION: 'Ошибка соединения',
    WEB2_CONNECTION_ERROR_DESCRIPTION: 'Произошла ошибка при подключении к серверу. Проверьте ваше интернет-соединение и повторите попытку.',
    WEB2_CONNECTION_ERROR_BTN_RETRY: 'Повторить',
    WEB2_MOBILE_LOADING_APK_TITLE: 'Идет обновление',
    WEB2_MOBILE_LOADING_APK_MESSAGE: 'Пожалуйста, дождитесь полной загрузки обновлений.',
    WEB2_MOBILE_UPDATE_APK_TITLE: 'Обновить приложение?',
    WEB2_MOBILE_UPDATE_APK_MESSAGE: 'В нашем приложении появились обновления. Установите их, чтобы продолжить игру.',
    WEB2_MOBILE_UPDATE_APK_BTN_UPDATE: 'Обновить',
    WEB2_MOBILE_UPDATE_APK_BTN_POSTPONE: 'Не сейчас',
    WEB2_IP_BLOCKED_ERROR: 'Сервис недоступен в вашей стране.',
    WEB2_UPDATE_IOS_TITLE: 'Обновите iOS',
    WEB2_UPDATE_IOS_MESSAGE: 'Текущая версия iOS не поддерживается. Пожалуйста, обновите ваше устройство, чтобы получить доступ ко всем функциям сайта.',
    WEB2_UPDATE_IOS_BUTTON: 'Обновить',
    WEB2_UPDATE_ANDROID_TITLE: 'Обновите Android',
    WEB2_UPDATE_ANDROID_MESSAGE: 'Текущая версия Android не поддерживается. Пожалуйста, обновите ваше устройство, чтобы получить доступ ко всем функциям сайта.',
    WEB2_UPDATE_ANDROID_BUTTON: 'Обновить'
});
export default ruTranslations;
