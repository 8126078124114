import { _ as _define_property } from "@swc/helpers/_/_define_property";
export class IndexedDb {
    async init() {
        if (this.db) return this.db;
        return new Promise((resolve, reject)=>{
            const request = indexedDB.open(this.dbName, 1);
            request.onerror = ()=>reject(request.error);
            request.onsuccess = ()=>{
                this.db = request.result;
                resolve(this.db);
            };
            request.onupgradeneeded = ()=>{
                const db = request.result;
                db.createObjectStore(this.storeName, {
                    keyPath: 'key'
                });
            };
        });
    }
    async setItem(key, value) {
        const db = await this.init();
        return new Promise((resolve, reject)=>{
            const transaction = db.transaction([
                this.storeName
            ], 'readwrite');
            const store = transaction.objectStore(this.storeName);
            const request = store.put({
                key,
                value
            });
            request.onsuccess = ()=>resolve();
            request.onerror = ()=>reject(request.error);
        });
    }
    async getItem(key) {
        const db = await this.init();
        return new Promise((resolve, reject)=>{
            const transaction = db.transaction([
                this.storeName
            ], 'readonly');
            const store = transaction.objectStore(this.storeName);
            const request = store.get(key);
            function isStorageStructure(value) {
                return 'object' == typeof value && null !== value && 'value' in value;
            }
            request.onsuccess = ()=>{
                const { result } = request;
                if (result) {
                    if (isStorageStructure(result)) resolve(result.value);
                    else reject(new Error('Invalid storage item', {
                        cause: request.result
                    }));
                } else resolve(void 0);
            };
            request.onerror = ()=>reject(request.error);
        });
    }
    async deleteItem(key) {
        const db = await this.init();
        return new Promise((resolve, reject)=>{
            const transaction = db.transaction([
                this.storeName
            ], 'readwrite');
            const store = transaction.objectStore(this.storeName);
            const request = store.delete(key);
            request.onsuccess = ()=>resolve();
            request.onerror = ()=>reject(request.error);
        });
    }
    async clear() {
        const db = await this.init();
        return new Promise((resolve, reject)=>{
            const transaction = db.transaction([
                this.storeName
            ], 'readwrite');
            const store = transaction.objectStore(this.storeName);
            const request = store.clear();
            request.onsuccess = ()=>resolve();
            request.onerror = ()=>reject(request.error);
        });
    }
    constructor({ dbName, storeName }){
        _define_property(this, "db", null);
        _define_property(this, "dbName", void 0);
        _define_property(this, "storeName", void 0);
        if ('undefined' == typeof indexedDB) throw new Error('Not supported');
        this.dbName = dbName;
        this.storeName = storeName;
    }
}
