import { themeEditions, layoutEditions, browserEditions, osEditions } from './entities';
import { EditionKey } from './enums';
function check(config) {
    return config;
}
export const runtimeEditionsConfig = Object.freeze(check({
    [EditionKey.OS]: osEditions,
    [EditionKey.LAYOUT]: layoutEditions,
    [EditionKey.BROWSER]: browserEditions,
    [EditionKey.THEME]: themeEditions
}));
