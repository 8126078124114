const createToString = (key)=>()=>key;
export function createServiceLocatorKey(key) {
    return {
        withType () {
            return {
                key,
                toString: createToString(key)
            };
        }
    };
}
