import { _ as _define_property } from "@swc/helpers/_/_define_property";
import GqlApiBatchedSubRequestError from './GqlApiBatchedSubRequestError';
let GqlApiG2svRequiredError = class GqlApiG2svRequiredError extends GqlApiBatchedSubRequestError {
    constructor(options){
        const { extensions } = options;
        super({
            ...options
        });
        _define_property(this, "extensions", void 0);
        this.extensions = extensions;
    }
};
export { GqlApiG2svRequiredError as default };
