import { isTrue, isValidInterface, createPrimitiveGuard } from './internal';
const hasSymbol = 'function' == typeof Symbol && 'symbol' == typeof Symbol.toStringTag;
const defaultGuard = (value)=>true;
const isStringValueModule = createPrimitiveGuard('Module');
export default function isESModule(value) {
    let guard = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : defaultGuard;
    const options = {
        __esModule: isTrue,
        default: guard
    };
    if (hasSymbol) Object.assign(options, {
        [Symbol.toStringTag]: isStringValueModule
    });
    return isValidInterface(value, options);
}
