import getBaseHeaders from '@leon-hub/api/src/client/getBaseHeaders';
import resolveApi2Url from '@leon-hub/api/src/helpers/resolveApi2Url';
import ApplicationRestClient from 'web/src/modules/core/services/api/ApplicationRestClient';
let DefaultRestApiClient = class DefaultRestApiClient extends ApplicationRestClient {
    constructor(){
        super({
            method: 'GET',
            baseUrl: resolveApi2Url(),
            headers: getBaseHeaders()
        });
    }
};
export { DefaultRestApiClient as default };
