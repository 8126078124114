import { isObject } from './internal';
/**
 * Complex object guard.
 * @param {object} input
 * @param {Validator[] | Validator[][]} rules
 * @param {boolean} silent
 *  provide array of rules to apply `OR logic`, or provide array of array of rules to apply `AND logic`
 */ export default function isValidObject(input, rules) {
    let silent = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : false;
    if (!isObject(input)) return false;
    const inputKeys = Object.keys(input);
    const rulesKeys = Object.keys(rules);
    const unknownKeys = rulesKeys.concat(inputKeys).filter((key)=>!rulesKeys.includes(key));
    if (unknownKeys.length > 0) {
        if (!silent) // eslint-disable-next-line no-console
        console.warn('Unexpected key:', ...unknownKeys);
        return false;
    }
    return rulesKeys.every((key)=>{
        const validators = rules[key];
        const result = validators.some((validator)=>{
            if (Array.isArray(validator)) return validator.every((andValidator)=>andValidator(input[key]));
            return validator(input[key]);
        });
        if (!result && !silent) {
            // eslint-disable-next-line no-console
            console.groupCollapsed('Invalid field', key, input[key]);
            // eslint-disable-next-line no-console
            console.log('target', input);
            // eslint-disable-next-line no-console
            console.groupEnd();
        }
        return result;
    });
}
export function bindIsValidObjectType(rules) {
    return (value)=>isValidObject(value, rules);
}
