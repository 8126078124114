import { getTranslations } from '@leon-hub/api-sdk';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
export default async function fetchTranslations(keys, silent) {
    const apiClient = useGraphqlClient();
    const response = await getTranslations(apiClient, (node)=>node.queries.system.getTranslationBundleByKeys, {
        options: {
            keys,
            mobile: true
        }
    }, {
        silent
    });
    return response.translations;
}
