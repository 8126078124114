import { _ as _define_property } from "@swc/helpers/_/_define_property";
export class ServiceLocator {
    mockProvider(key, options) {
        return ()=>void 0;
    }
    registerProvider(key, options) {
        if (this.services.has(key)) {
            const errMsg = `Provider for key "${key.key}" is already registered`;
            // eslint-disable-next-line no-console
            console.error(errMsg);
            return;
        }
        this.services.set(key, options);
    }
    getProvider(key) {
        const options = this.services.get(key);
        if (options) return ()=>{
            const value = options.value();
            if (options.required && !value) throw new Error(`Invalid provided value for key ${key.key}: "${value}"`);
            return value;
        };
        throw new Error(`Service not found: ${key.key}`);
    }
    getNonConfiguredKeys() {
        const loadedKeys = [
            ...this.services.keys()
        ];
        return this.keys.filter((key)=>!loadedKeys.includes(key)).map((key)=>key.toString());
    }
    isConfigured() {
        return !this.getNonConfiguredKeys().length;
    }
    constructor(keys){
        _define_property(this, "keys", void 0);
        _define_property(this, "services", void 0);
        this.keys = keys;
        this.services = new Map();
    }
}
