import { toEnvVar } from './toEnvVar';
import { EnvValueDisabled, EnvValueEnabled } from '../constants';
import { composeEnvNames } from './composeEnvNames';
export const createEnvVarSetter = (config, envPrefix)=>(env, key, value)=>{
        const composedEnvNames = composeEnvNames(envPrefix, key, config[key]);
        const activeComposedEnvName = value ? toEnvVar(envPrefix, `${key}_${value}`) : void 0;
        const resultEnv = {
            ...env,
            [toEnvVar(envPrefix, key)]: value
        };
        return composedEnvNames.reduce((acc, composedEnvName)=>({
                ...acc,
                [composedEnvName]: composedEnvName === activeComposedEnvName ? EnvValueEnabled : EnvValueDisabled
            }), resultEnv);
    };
