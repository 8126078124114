import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { ApiError } from '../../errors/ApiError';
let GqlApiError = class GqlApiError extends ApiError {
    constructor(options){
        super({
            ...options,
            code: options?.code
        });
        _define_property(this, "batch", void 0);
        _define_property(this, "subRequest", void 0);
        _define_property(this, "responseItem", void 0);
        this.batch = options?.batch;
        this.subRequest = options?.subRequest;
        this.responseItem = options?.responseItem;
    }
};
export { GqlApiError as default };
