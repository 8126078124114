import { getBootstrapTranslations } from '@leon-hub/bootstrap-translations';
import { ApiErrorCode } from './ApiErrorCode';
import { ApiError } from './ApiError';
export class ApiIpBlockedError extends ApiError {
    constructor(options){
        super({
            ...options,
            message: options?.message ?? getBootstrapTranslations().WEB2_IP_BLOCKED_ERROR,
            code: ApiErrorCode.API_IP_BLOCKED_ERROR
        });
    }
}
