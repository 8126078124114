import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { _ as _ts_decorate } from "@swc/helpers/_/_ts_decorate";
import { _ as _ts_metadata } from "@swc/helpers/_/_ts_metadata";
import debug from 'debug';
import { IndexedDb } from '@leon-hub/indexed-db';
import { memoize, Memoized } from '@leon-hub/utils';
import { getBootstrapTranslations } from '@leon-hub/bootstrap-translations';
import { getLocationOrigin, getCookies } from '@leon-hub/service-locator-env';
const defaultCustomHeaders = {};
const DEFAULT_BASE_URL = '';
let BaseClient = class BaseClient {
    setConnectionErrorResolver(value) {
        this.connectionErrorResolver = value;
    }
    // eslint-disable-next-line rulesdir/class-method-use-this-regex,class-methods-use-this
    get bootstrapTranslations() {
        return getBootstrapTranslations();
    }
    getCredentials() {
        return this.credentials;
    }
    getBaseUrl() {
        return this.baseUrl;
    }
    setBaseUrl(baseUrl) {
        this.baseUrl = baseUrl.replace(/\/+$/, '');
    }
    getOrigin() {
        return this.origin;
    }
    getCustomHeaders() {
        return this.customHeaders;
    }
    getCustomHeader() {
        return this.customHeaders;
    }
    setCustomHeaders(headers) {
        this.customHeaders = {
            ...this.customHeaders,
            ...headers
        };
    }
    getHeaders() {
        let customHeaders = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : defaultCustomHeaders;
        const allHeaders = {
            ...this.headers,
            ...this.customHeaders,
            ...customHeaders
        };
        if (this.cookies) allHeaders.cookie = this.cookies;
        return Object.keys(allHeaders).filter((key, index, list)=>key && allHeaders[key] && list.indexOf(key) === index).reduce((accumulator, key)=>{
            const value = allHeaders[key];
            return value ? {
                ...accumulator,
                [key.toLowerCase()]: value
            } : accumulator;
        }, {});
    }
    getDefaultMethod() {
        return this.method;
    }
    setHeaders(headers) {
        Object.assign(this.headers, headers);
    }
    setDefaultCacheTtl(ttl) {
        this.defaultCacheTTL = ttl;
    }
    async setCache(cacheKey, value, ttl) {
        const isRationalNumber = Number.isFinite(ttl) && ttl > 0;
        return this.getCacheDb().setItem(cacheKey, {
            expires: isRationalNumber ? Date.now() + ttl : 0,
            value
        });
    }
    async getCache(cacheKey) {
        const cacheItem = await this.getCacheDb().getItem(cacheKey);
        if (!cacheItem || Date.now() > (cacheItem.expires || 1 / 0)) return;
        return cacheItem.value;
    }
    constructor(options){
        _define_property(this, "origin", void 0);
        _define_property(this, "baseUrl", void 0);
        _define_property(this, "method", void 0);
        _define_property(this, "headers", void 0);
        _define_property(this, "credentials", void 0);
        _define_property(this, "debug", debug('client'));
        _define_property(this, "customHeaders", {});
        _define_property(this, "connectionErrorResolver", null);
        _define_property(this, "cookies", '');
        _define_property(this, "defaultCacheTTL", 0);
        _define_property(this, "getCacheDb", memoize(()=>new IndexedDb({
                dbName: 'cache',
                storeName: 'api'
            })));
        this.baseUrl = options.baseUrl ?? DEFAULT_BASE_URL;
        this.origin = options.origin ?? getLocationOrigin().replace(/\/+$/, '');
        if (this.baseUrl.startsWith('http')) this.origin = '';
        this.method = options.method;
        this.headers = options.headers ?? {};
        this.credentials = options.credentials ?? 'include';
        this.cookies = getCookies();
    }
};
export { BaseClient as default };
_ts_decorate([
    Memoized(),
    _ts_metadata("design:type", Function),
    _ts_metadata("design:paramtypes", [
        "undefined" == typeof Record ? Object : Record
    ]),
    _ts_metadata("design:returntype", "undefined" == typeof Record ? Object : Record)
], BaseClient.prototype, "getHeaders", null);
