import { CurrenciesIconName, EsportIconName, LegacyIconName, SlottIconName, SlottSportIconName, CurrenciesSlottIconName, SocialIconName, SportIconName, SystemIconName, SlottSocialIconName } from '../enums';
export const IconName = {
    ...LegacyIconName,
    ...SystemIconName,
    ...SportIconName,
    ...EsportIconName,
    ...CurrenciesIconName,
    ...SocialIconName,
    ...SlottSocialIconName,
    // @TODO remove after clear all IconName.SLOTT.. usages
    ...SlottIconName,
    ...CurrenciesSlottIconName,
    ...SlottSportIconName
};
