// eslint-disable global-require
export default function providePolyfills() {
    // always for legacy build and for modern build in case if ddos-guard removes allSettled by accident
    require('allsettled-polyfill');
    require('smoothscroll-polyfill').polyfill();
    require('element-scroll-polyfill');
    // @ts-ignore
    require('promise.prototype.finally').shim();
    require('abort-controller/polyfill');
    // prevent overriding of Promise in 3rd-party scripts
    Object.defineProperty(window, 'Promise', {
        value: window.Promise,
        writable: false,
        configurable: false
    });
}
