/* eslint-disable unicorn/consistent-function-scoping */ import { _ as _define_property } from "@swc/helpers/_/_define_property";
const createNoopResolver = ()=>function deferredResolver() {};
const createNoopRejecter = ()=>function deferredRejecter() {};
let Deferred = class Deferred {
    get resolved() {
        return this.state.resolved;
    }
    get rejected() {
        return this.state.rejected;
    }
    get finished() {
        return this.state.resolved || this.state.rejected;
    }
    resolve(value) {
        const { state } = this;
        if (state.resolved || state.rejected) return;
        state.resolved = true;
        state.rejected = false;
        state.result = value;
        state.resolver(value);
    }
    reject(error) {
        const { state } = this;
        if (state.resolved || state.rejected) return;
        this.state.resolved = false;
        this.state.rejected = true;
        this.state.rejecter(error);
    }
    constructor(){
        _define_property(this, "promise", void 0);
        _define_property(this, "state", {
            resolved: false,
            rejected: false,
            resolver: createNoopResolver(),
            rejecter: createNoopRejecter()
        });
        this.promise = new Promise((resolve, reject)=>{
            this.state.resolver = resolve;
            this.state.rejecter = reject;
        });
        this.resolve = this.resolve.bind(this);
        this.reject = this.reject.bind(this);
    }
};
export default Deferred;
