import { isFunction } from '@leon-hub/guards';
import { memoize } from '../function/memoize';
export default function Memoized() {
    let options = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    return function memoizeDecorator(target, key, descriptor) {
        isFunction(descriptor.value);
        // Decorator value must be configured via reassignment.
        // eslint-disable-next-line no-param-reassign,@typescript-eslint/no-unsafe-argument
        descriptor.value = memoize(descriptor.value, options);
        return descriptor;
    };
}
