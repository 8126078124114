import { isNumber } from '@leon-hub/guards';
import { QueryParameterName } from '@leon-hub/query-manager';
import { getBuiltinConfig, getQueryParams } from '@leon-hub/service-locator-env';
let canUseConfig = true;
const initialTimeExpiry = 10000;
export default function createCoreApi(client) {
    return {
        async syncServerTime () {
            // TODO: mv condition to function.
            if ('performance' === getQueryParams()[QueryParameterName.TEST_CASE]) return Date.now();
            if (canUseConfig) {
                const { docParseTime, time } = getBuiltinConfig();
                if (time && docParseTime) {
                    const now = Date.now();
                    if (time > now - initialTimeExpiry) {
                        const timeFromParseToNow = now - docParseTime;
                        return time + timeFromParseToNow;
                    }
                }
                canUseConfig = false;
            }
            return client.request({
                id: 'time/sync',
                endpoint: '/time',
                guard: isNumber,
                silent: true
            });
        }
    };
}
