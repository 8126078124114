const getTestingKeyName = ()=>'__TEST_KEY__';
const TEST_VALUE = '1';
let cache;
export function isLocalStorageAvailable() {
    if (navigator && false === navigator.cookieEnabled) return false;
    if (void 0 !== cache) return cache;
    const { localStorage } = globalThis;
    try {
        if (!localStorage) {
            cache = false;
            return cache;
        }
        const key = getTestingKeyName();
        localStorage.setItem(key, TEST_VALUE);
        cache = localStorage.getItem(key) === TEST_VALUE;
        return cache;
    } catch  {} finally// empty
    {
        try {
            localStorage.removeItem(getTestingKeyName());
        } catch  {}
    }
    // empty
    return false;
}
