import { EditionValueBrowserChrome, EditionValueBrowserSafari, EditionValueBrowserEdge, EditionValueBrowserFirefox, EditionValueBrowserYandex, EditionValueBrowserOpera, EditionValueBrowserNo } from '../enums';
export const browserEditions = Object.freeze([
    EditionValueBrowserChrome,
    EditionValueBrowserSafari,
    EditionValueBrowserEdge,
    EditionValueBrowserFirefox,
    EditionValueBrowserYandex,
    EditionValueBrowserOpera,
    EditionValueBrowserNo
]);
