import { normalizeError } from '@leon-hub/errors';
import { promiseTimeout } from '@leon-hub/utils';
export default async function doFetch(url, options, timeout, onError, controller, preparedRequestCallback) {
    const abortController = controller ?? new AbortController();
    let isAborted = false;
    abortController.signal.addEventListener('abort', ()=>{
        isAborted = true;
    });
    const request = Object.assign(new Request(url, {
        ...options,
        signal: abortController.signal
    }), {
        toJSON: ()=>({
                url,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                headers: Object.fromEntries(request.headers.entries()),
                body: options.body
            })
    });
    if (preparedRequestCallback) preparedRequestCallback(request);
    return promiseTimeout({
        timeout: timeout ?? 15000,
        promise: fetch(request),
        error: onError ? (originalError)=>onError(normalizeError(originalError), request) : void 0,
        onTimeout: ()=>isAborted || abortController.abort()
    });
}
