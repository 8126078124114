import { isString, isBoolean, isValidInterface, isObjectValues, isOptionalNumber } from '@leon-hub/guards';
export default function isBuiltinConfig(target) {
    return void 0 === target || isValidInterface(target, {
        isNT: isBoolean,
        isQueryHashingEnabled: isBoolean,
        time: isOptionalNumber,
        docParseTime: isOptionalNumber,
        theme: (value)=>isObjectValues([
                isString
            ], value),
        adPaths: (value)=>isObjectValues([
                isString
            ], value)
    });
}
