/* eslint-disable no-console */ import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { isUndefined, isObject } from '@leon-hub/guards';
let Json = class Json {
    static configure(options) {
        Json.silent = isUndefined(options.silent) ? Json.silent : options.silent;
    }
    static parse(json, options) {
        try {
            const result = JSON.parse(json);
            if (options?.validator?.(result) === false) {
                console.warn('Unexpected parsed value');
                return null;
            }
            return result;
        } catch (error) {
            if (!Json.silent) console.warn('Error parsing JSON:', error);
            return options?.defaultValue ?? null;
        }
    }
    static stringify(json, options) {
        try {
            return options?.circular ? Json.stringifyCircular(json, options) : JSON.stringify(json, options?.replacer);
        } catch (error) {
            if (!Json.silent) console.warn('Error stringify JSON:', error);
            return options?.defaultValue ?? null;
        }
    }
    static stringifyCircular(json, options) {
        let cacheObject = [];
        const result = JSON.stringify(json, (key, value)=>{
            if (isObject(value) && null !== value) {
                if (cacheObject.includes(value)) return options.circularPlaceholder || null;
                cacheObject.push(value);
            }
            return value;
        });
        cacheObject = null;
        return result;
    }
};
_define_property(Json, "silent", false);
export { Json as default };
