import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { LogLevel } from '@leon-hub/errors';
export class NameSpacedLogger {
    info(input, payload, error) {
        this.logger.sendLog(LogLevel.INFO, input, payload, error, this.namespace);
    }
    debug(input, payload, error) {
        this.logger.sendLog(LogLevel.DEBUG, input, payload, error, this.namespace);
    }
    warn(input, payload, error) {
        this.logger.sendLog(LogLevel.WARN, input, payload, error, this.namespace);
    }
    error(input, payload, error) {
        this.logger.sendLog(LogLevel.ERROR, input, payload, error, this.namespace);
    }
    createNamespace(namespace) {
        return new NameSpacedLogger(this.logger, namespace ? [
            ...this.namespace,
            namespace
        ] : [
            ...this.namespace
        ]);
    }
    constructor(logger, namespace){
        _define_property(this, "logger", void 0);
        _define_property(this, "namespace", void 0);
        this.logger = logger;
        this.namespace = namespace;
    }
}
