import { createServiceLocatorKey } from '@leon-hub/service-locator';
export const HTTPS = createServiceLocatorKey('HTTPS').withType();
export const LOCATION_HREF = createServiceLocatorKey('LOCATION_HREF').withType();
export const LOCATION_ORIGIN = createServiceLocatorKey('LOCATION_ORIGIN').withType();
export const LOCATION_HOST = createServiceLocatorKey('LOCATION_HOST').withType();
export const LOCATION_PATHNAME = createServiceLocatorKey('LOCATION_PATHNAME').withType();
export const LOCATION_HASH = createServiceLocatorKey('LOCATION_HASH').withType();
export const LOCATION_SEARCH = createServiceLocatorKey('LOCATION_SEARCH').withType();
export const REFERRER = createServiceLocatorKey('REFERRER').withType();
export const COOKIES = createServiceLocatorKey('COOKIES').withType();
export const USER_AGENT = createServiceLocatorKey('USER_AGENT').withType();
export const BUILTIN_CONFIG = createServiceLocatorKey('BUILTIN_CONFIG').withType();
export const QUERY_PARAMS = createServiceLocatorKey('QUERY_PARAMS').withType();
export const ONLINE_STATUS = createServiceLocatorKey('ONLINE_STATUS').withType();
