import { isBoolean, isNumber, isString } from '@leon-hub/guards';
function toString(value, defaultValue) {
    return isString(value) ? value : defaultValue;
}
function toNumber(value, defaultValue) {
    const numberValue = Number(value);
    if (Number.isFinite(numberValue) && String(numberValue) === value) return numberValue;
    return defaultValue;
}
function toBoolean(value, defaultValue) {
    if ('true' === value) return true;
    if ('false' === value) return false;
    return defaultValue;
}
function getQueryParameter(searchParameters, key, type, defaultValue) {
    const value = searchParameters.get(key);
    if (isString(value)) {
        if ('string' === type) {
            isString(defaultValue);
            return toString(value, defaultValue);
        }
        if ('number' === type) {
            isNumber(defaultValue);
            return toNumber(value, defaultValue);
        }
        if ('boolean' === type) {
            isBoolean(defaultValue);
            return toBoolean(value, defaultValue);
        }
    }
    return defaultValue;
}
export default getQueryParameter;
