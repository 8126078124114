import { localeStorageKey } from '@leon-hub/locale';
const fallBackLocales = {
    ru: 'ru_RU',
    ru_KZ: 'ru_RU',
    ru_UA: 'ru_RU',
    ru_UZ: 'ru_RU',
    de: 'de_DE',
    pt: 'pt_PT',
    pt_BR: 'pt_PT',
    es: 'es_ES',
    es_PE: 'es_ES',
    es_MX: 'es_ES',
    es_CL: 'es_ES',
    fr: 'fr_FR',
    fr_CA: 'fr_FR',
    vi: 'vi_VN',
    tr: 'tr_TR',
    th: 'th_TH',
    el: 'el_GR'
};
export default function getInitLocale() {
    const locale = window.localStorage?.getItem(localeStorageKey) || window.navigator.language.replace('-', '_');
    if (fallBackLocales[locale]) return fallBackLocales[locale];
    if (locale.includes('_')) return locale;
    return '';
}
