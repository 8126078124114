import { isNull } from './internal';
export function isEnumValueType(enumValue, value) {
    return typeof Object.values(enumValue)[0] === typeof value;
}
export function bindIsEnumValueType(enumValue) {
    return (value)=>isEnumValueType(enumValue, value);
}
export function bindIsMaybeEnumValueType(enumValue) {
    return (value)=>isNull(value) || isEnumValueType(enumValue, value);
}
