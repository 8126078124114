import { _ as _define_property } from "@swc/helpers/_/_define_property";
export class MemoryStorageManager {
    clear() {
        this.store = {};
    }
    getItem(key) {
        return this.store[key] ?? null;
    }
    setItem(key, value) {
        this.store[key] = value;
    }
    removeItem(key) {
        delete this.store[key];
    }
    key(index) {
        return Object.keys(this.store)[index] ?? null;
    }
    get length() {
        return Object.keys(this.store).length;
    }
    constructor(store = {}){
        _define_property(this, "store", void 0);
        this.store = store;
    }
}
