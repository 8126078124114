import { _ as _define_property } from "@swc/helpers/_/_define_property";
import GqlApiResponseErrorCode from './GqlApiResponseErrorCode';
import GqlApiError from './GqlApiError';
let GqlApiBatchedSubRequestError = class GqlApiBatchedSubRequestError extends GqlApiError {
    isMutationRequest() {
        return !!this.subRequest?.mutation;
    }
    constructor(options){
        const { extensions } = options;
        const errorCode = options.code ?? new GqlApiResponseErrorCode(extensions.errorCode);
        super({
            ...options,
            code: errorCode,
            message: options.message || extensions.message || void 0
        });
        _define_property(this, "extensions", void 0);
        this.extensions = extensions;
    }
};
export { GqlApiBatchedSubRequestError as default };
