import GqlApiBatchedSubRequestError from '@leon-hub/api/src/client/graphql/errors/GqlApiBatchedSubRequestError';
import { ApiErrorCode } from '../../errors/ApiErrorCode';
export class GqlApiPreviousRequestHasNotExpired extends GqlApiBatchedSubRequestError {
    constructor(options){
        super({
            ...options,
            code: ApiErrorCode.API_PREVIOUS_REQUEST_HAS_NOT_EXPIRED
        });
    }
}
