/* eslint-disable no-console */ import { LogLevel } from '@leon-hub/errors';
export const consoleLog = (level, initMessage, trace, payload)=>{
    let message = initMessage;
    if (trace) message += `\n[Stacktrace]: ${trace}`;
    const consoleInput = [
        message
    ];
    if (payload) consoleInput.push(payload);
    switch(level){
        case LogLevel.INFO:
            console.info(...consoleInput);
            break;
        case LogLevel.WARN:
            console.warn(...consoleInput);
            break;
        case LogLevel.DEBUG:
            console.debug(...consoleInput);
            break;
        case LogLevel.ERROR:
            console.error(...consoleInput);
            break;
        default:
            break;
    }
};
export const consoleLogWithTime = (level, prefix, message)=>{
    const timestamp = `${new Date().toLocaleTimeString('en-GB')}.${String(new Date().getMilliseconds()).padStart(3, '0')}`;
    consoleLog(level, `[${timestamp}] ${prefix} ${message}`);
};
