// eslint-disable-next-line import/prefer-default-export
export function getDefaultSettings() {
    return {
        cacheLength: 1000,
        minAccumTime: 20,
        maxAccumTime: 40,
        maxBatchQueueSize: 6,
        maxRequestRetriesCount: 3,
        requestTimeout: 15000
    };
}
