import { isString, isUndefined, isOptionalBoolean, isNumber, isBoolean, isValidObject, isArrayOf, isObjectValues, isOptionalString, isOptionalNumber } from '@leon-hub/guards';
import { isOptionalAnyIconName, isIconSize } from '@leon-hub/icons';
import isNull from '@leon-hub/guards/src/isNull';
// TODO: state: move to store getters
// TODO: state: move to store
// TODO: state: move to store
// TODO: state: move to store
export function isNavigationItemIcon(value) {
    return isString(value) || isValidObject(value, {
        name: [
            isOptionalAnyIconName
        ],
        size: [
            isIconSize
        ]
    });
}
export function isNavigationItemCdnIcon(value) {
    return isString(value) || isValidObject(value, {
        src: [
            isString
        ],
        width: [
            isUndefined,
            isString
        ],
        height: [
            isUndefined,
            isString
        ]
    });
}
export function isNavigationItem(value) {
    return isValidObject(value, {
        id: [
            isString
        ],
        routeName: [
            isOptionalString
        ],
        url: [
            isOptionalString
        ],
        caption: [
            isOptionalString
        ],
        label: [
            isOptionalString
        ],
        icon: [
            isUndefined,
            isNavigationItemIcon
        ],
        iconSrc: [
            isOptionalString
        ],
        iconCdn: [
            isUndefined,
            isNavigationItemCdnIcon
        ],
        children: [
            isUndefined,
            isArrayOf.bind(null, isNavigationItem)
        ],
        props: [
            isUndefined,
            isObjectValues.bind(null, [
                isNumber,
                isString,
                isBoolean
            ])
        ],
        asModal: [
            isOptionalBoolean
        ],
        isActive: [
            isOptionalBoolean
        ],
        badge: [
            isOptionalString
        ],
        progress: [
            isOptionalNumber
        ],
        suffixText: [
            isOptionalString
        ],
        parentId: [
            isNull,
            isOptionalString
        ],
        type: [
            isOptionalString
        ]
    });
}
