/**
 * Based on https://github.com/afiedler/memoized-decorator/blob/master/src/memoize.js
 */ import { _ as _define_property } from "@swc/helpers/_/_define_property";
function isPrimitive(value) {
    const type = typeof value;
    return null === value || 'object' !== type && 'function' !== type;
}
const undefinedResult = Symbol('undefined');
let Node = class Node {
    /**
   * @param {MemoizeArgument} key
   * @param {Node} node
   * @param {object} options
   * @param {boolean} options.allowMutation allow mutate key from outside.
   * @param {boolean} options.serialize allow to serialize arguments.
   */ set(key, node) {
        let options = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {};
        if (isPrimitive(key)) this.map.set(key, node);
        else if (options.serialize) this.map.set(JSON.stringify(key), node);
        else this.weakMap.set(key, node);
    }
    get(key, options) {
        if (isPrimitive(key)) return this.map.get(key);
        if (options.serialize) return this.map.get(JSON.stringify(key));
        return this.weakMap.get(key);
    }
    has(key, options) {
        if (isPrimitive(key)) return this.map.has(key);
        if (options.serialize) return this.map.has(JSON.stringify(key));
        return this.weakMap.has(key);
    }
    constructor(){
        _define_property(this, "weakMap", new WeakMap());
        _define_property(this, "map", new Map());
        _define_property(this, "value", void 0);
    }
};
// Skip memoize behaviour
// User per class cache.
// Serialize non-primitive arguments.
export function memoize(callback) {
    let options = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    const root = new Node();
    const { exceptWhen, shared = false, serialize = false } = options;
    // eslint-disable-next-line sonarjs/cognitive-complexity
    const memoized = function() {
        for(var _len = arguments.length, rest = new Array(_len), _key = 0; _key < _len; _key++){
            rest[_key] = arguments[_key];
        }
        let localRoot = root;
        const setterOptions = serialize ? {
            serialize
        } : {
            allowMutation: true
        };
        const getterOptions = {
            serialize
        };
        // Split cache per instance.
        if (!shared) {
            const hasInstanceRoot = root.has(this, getterOptions);
            localRoot = hasInstanceRoot ? root.get(this, getterOptions) : new Node();
            if (!hasInstanceRoot) root.set(this, localRoot, setterOptions);
        }
        let node = localRoot;
        const restLength = rest.length;
        for(let index = 0; index < restLength; index += 1){
            const argument = rest[index];
            if (exceptWhen?.(argument, index)) return Reflect.apply(callback, this, rest);
            node = node.get(argument, getterOptions);
            if (void 0 === node) break;
        }
        if (!node || void 0 === node.value) {
            const rawValue = Reflect.apply(callback, this, rest);
            const value = void 0 === rawValue ? undefinedResult : rawValue;
            const restCopy = [
                ...rest
            ];
            node = localRoot;
            while(restCopy.length > 0){
                const argument = restCopy.shift();
                if (node.has(argument, getterOptions)) node = node.get(argument, getterOptions);
                else {
                    const newNode = new Node();
                    node.set(argument, newNode, setterOptions);
                    node = newNode;
                }
            }
            node.value = value;
        }
        return node.value === undefinedResult ? void 0 : node.value;
    };
    Object.defineProperty(memoized, 'name', {
        writable: false,
        enumerable: false,
        configurable: true,
        value: `${callback.name}_memoized`
    });
    return memoized;
}
