import debounce from 'lodash/debounce';
import { isFunction } from '@leon-hub/guards';
export default function Debounce(options) {
    return function memoizeDecorator(target, key, descriptor) {
        isFunction(descriptor.value);
        // Decorator value must be configured via reassignment.
        // eslint-disable-next-line no-param-reassign
        descriptor.value = debounce(descriptor.value, options.wait, options);
        return descriptor;
    };
}
