import { _ as _define_property } from "@swc/helpers/_/_define_property";
import GqlApiBatchError from './GqlApiBatchError';
import GqlApiErrorCode from './GqlApiErrorCode';
let GqlApiBatchMaxSizeError = class GqlApiBatchMaxSizeError extends GqlApiBatchError {
    constructor(options){
        super({
            ...options,
            code: GqlApiErrorCode.API_TECHNICAL_ERROR
        });
        _define_property(this, "batchMaxSize", void 0);
        this.batchMaxSize = options.batchMaxSize;
    }
};
export { GqlApiBatchMaxSizeError as default };
