const BTR_PREFIX = 'BTR_';
function isBtrKey(message) {
    return message?.startsWith(BTR_PREFIX);
}
function normalizeBtrKey(message) {
    return message.slice(BTR_PREFIX.length);
}
export default {
    BTR_PREFIX,
    isBtrKey,
    normalizeBtrKey
};
