function isScalar(argument) {
    if ('string' == typeof argument) return true;
    if ('boolean' == typeof argument) return true;
    if ('number' == typeof argument && Number.isFinite(argument)) return true;
    if (null === argument) return true;
    return false;
}
/**
 * Converts object to query
 * @example
 * console.log(stringifyQuery({id: undefined, age: 18, email: 'user@example.com'}));
 * "age=18&email=user@example.com"
 */ export default function stringifyQuery(parameters) {
    const input = parameters;
    return Object.keys(parameters).reduce((query, key)=>{
        const value = input[key];
        if (!key || !isScalar(value)) return query;
        const pair = '' === value ? key : `${key}=${value}`;
        if (!query) return pair;
        return `${query}&${pair}`;
    }, '');
}
