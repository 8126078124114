export const getLargestKey = (storage)=>{
    let largestKey;
    let largestLength = 0;
    for (const key of Object.keys(storage)){
        const keyLength = storage.getItem(key)?.length || 0;
        if (keyLength > largestLength) {
            largestLength = keyLength;
            largestKey = key;
        }
    }
    return largestKey;
};
