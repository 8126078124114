import RestClient from '@leon-hub/api/src/client/rest/RestClient';
import { ApiConnectionError } from '@leon-hub/api';
import { logger } from '@leon-hub/logging';
let ApplicationRestClient = class ApplicationRestClient extends RestClient {
    async request(options) {
        try {
            return await super.request(options);
        } catch (error) {
            if (this.connectionErrorResolver && error instanceof ApiConnectionError && !error.silent) try {
                logger.debug(`Connection error Rest client: ${options.endpoint}`);
                await this.connectionErrorResolver(error);
                return this.request(options);
            } catch  {}
            throw error;
        }
    }
    constructor(options){
        super({
            ...options,
            origin: options.origin ?? (process.env.VUE_APP_PRERENDER ? '' : void 0)
        });
    }
};
export { ApplicationRestClient as default };
