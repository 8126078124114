/* eslint-disable max-len */ const deTranslations = Object.freeze({
    WEB2_ERROR: 'Fehler',
    WEB2_SESSION_EXPIRED: 'Sitzung abgelaufen',
    CONNECTION_ERROR: 'Verbindungsfehler. Überprüfen Sie bitte Ihre Internetverbindung und versuchen Sie es erneut.',
    WEB2_TECHNICAL_ERROR: 'Es ist ein technischer Fehler aufgetreten. Bitte lade die Seite neu oder versuche es später noch einmal.',
    JSP_PCL_FBOT_CLOSE: 'Schließen',
    JS_CAPTION_ATTENTION: 'Fehler',
    WEB2_DO_LOGIN: 'Anmelden',
    JSPNAV_USTAT_REGISTER: 'Registrieren',
    WEB2_ERROR_503_TITLE_V2: 'SLOTT wird gewartet',
    WEB2_ERROR_503_TEXT1_V2: 'Wir führen derzeit eine planmäßige Systemwartung durch.',
    WEB2_ERROR_503_TEXT2_V2: 'Bitte versuche es später noch einmal.',
    WEB2_ERROR_503_TEXT3_V2: 'Wir entschuldigen uns für die Unannehmlichkeiten.',
    WEB2_ERROR_503_BTN_UPDATE_V2: 'Aktualisieren',
    WEB2_HELP_EMAIL: "info@leonbets.com",
    WEB2_ERROR_BTN_GO_HOME: 'Zurück zur Startseite',
    WEB2_ERROR_BTN_SUPPORT: 'Unterstützung',
    WEB2_ERROR_TEXT_SUPPORT: 'Benötigst du Hilfe? Wende dich an uns – wir sind für dich da!',
    WEB2_ERROR_404_TITLE: 'Ups! Seite nicht gefunden',
    WEB2_ERROR_404_MSG: 'Möglicherweise wurde die Seite entfernt oder existiert nicht.',
    WEB2_ERROR_403_TITLE: 'Zugriff verweigert',
    WEB2_ERROR_403_MSG: 'Du hast anscheinend keine Berechtigung, diese Seite anzuzeigen. Gehen wir zurück zur Startseite.',
    WEB2_CONNECTION_ERROR_CAPTION: 'Verbindungsfehler',
    WEB2_CONNECTION_ERROR_DESCRIPTION: 'Bitte überprüfe deine Internetverbindung und versuche es erneut.',
    WEB2_CONNECTION_ERROR_BTN_RETRY: 'Erneut versuchen',
    WEB2_MOBILE_LOADING_APK_TITLE: 'Aktualisiere die App',
    WEB2_MOBILE_LOADING_APK_MESSAGE: 'Bitte warte, bis das Update abgeschlossen ist.',
    WEB2_MOBILE_UPDATE_APK_TITLE: 'App-Update verfügbar',
    WEB2_MOBILE_UPDATE_APK_MESSAGE: 'Bitte aktualisiere die App, um sie weiterhin zu verwenden',
    WEB2_MOBILE_UPDATE_APK_BTN_UPDATE: 'Aktualisierung',
    WEB2_MOBILE_UPDATE_APK_BTN_POSTPONE: 'Später',
    WEB2_IP_BLOCKED_ERROR: 'Dieser Dienst ist in deinem Land nicht verfügbar.',
    WEB2_UPDATE_IOS_TITLE: 'iOS Update Required',
    WEB2_UPDATE_IOS_MESSAGE: 'Ihre aktuelle iOS Version wird nicht unterstützt. Bitte aktualisieren Sie Ihr Gerät, um von den neuesten Verbesserungen zu profitieren.',
    WEB2_UPDATE_IOS_BUTTON: 'Update iOS now',
    WEB2_UPDATE_ANDROID_TITLE: 'Android Update Required',
    WEB2_UPDATE_ANDROID_MESSAGE: 'Ihre derzeitige Android-Version wird nicht unterstützt. Bitte aktualisieren Sie Ihr Gerät, um von den neuesten Verbesserungen zu profitieren.',
    WEB2_UPDATE_ANDROID_BUTTON: 'Update Android now'
});
export default deTranslations;
